import { ShoppingCartDeliveryOptions, ShoppingCartProducts } from '../store/reducers/CartReducer';
import { DeliverOptionsNode, ProductNode } from '../interfaces/contentful';

export const calculateAmount = (product: (ProductNode | DeliverOptionsNode) & { quantity: number }): number => {
  if ('discountedPrice' in product) {
    const price = product.discountedPrice || product.price;
    return (price * 100 * product.quantity) / 100;
  } else {
    return (product.price * 100 * product.quantity) / 100;
  }
};

export const calculateTotalAmount = (products: ShoppingCartProducts): number => {
  return (
    Object.values(products).reduce((acc, product) => {
      const price = product.discountedPrice || product.price;
      return acc + price * 100 * product.quantity;
    }, 0) / 100
  );
};

export const calculateDeliveryAmount = (deliveryOptions: ShoppingCartDeliveryOptions): number => {
  return (
    Object.values(deliveryOptions).reduce((acc, deliveryOption) => {
      return acc + deliveryOption.price * 100 * deliveryOption.quantity;
    }, 0) / 100
  );
};

export const calculateTotalAmountWithDelivery = (
  products: ShoppingCartProducts,
  deliveryOptions: ShoppingCartDeliveryOptions,
): number => {
  const totalAmountInCents = calculateTotalAmount(products) * 100;
  const deliveryAmountInCents = calculateDeliveryAmount(deliveryOptions) * 100;

  return (totalAmountInCents + deliveryAmountInCents) / 100;
};
