import React, { useContext } from 'react';
import {
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cartStyle } from '../../assets/jss/pages/cartStyle';
import { formatPrice } from '../../helpers/formatterHelper';
import { Button } from '../Button/Button';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import { calculateAmount } from '../../helpers/pricingHelper';
import { ShoppingCartDeliveryOptions } from '../../store/reducers/CartReducer';
import { CartDispatchContext } from '../../store/StateProvider';
import { FormattedMessage } from 'react-intl';

interface DeliveryDetailsTableBlockProps {
  deliveryOptions: ShoppingCartDeliveryOptions;
}

const useStyles = makeStyles(cartStyle);

export const DeliveryCostsTableBlock: React.FC<DeliveryDetailsTableBlockProps> = ({ deliveryOptions }) => {
  const dispatch = useContext(CartDispatchContext);
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ textTransform: 'uppercase' }}>
            <TableCell colSpan={2} align="left" style={{ width: '289px' }}>
              <FormattedMessage id="cart.shipping.deliveryCosts" defaultMessage="Delivery costs" />
            </TableCell>
            <TableCell align="right" style={{ width: '150px' }}>
              <FormattedMessage id="cart.shipping.deliveryCosts.price" defaultMessage="Price" />
            </TableCell>
            <TableCell align="right" style={{ width: '220px' }}>
              <FormattedMessage id="cart.shipping.deliveryCosts.quantity" defaultMessage="QTY" />
            </TableCell>
            <TableCell align="right" style={{ width: '150px' }}>
              <FormattedMessage id="cart.shipping.deliveryCosts.amount" defaultMessage="Amount" />
            </TableCell>
            <TableCell style={{ width: '80px' }}>&nbsp;</TableCell>{' '}
          </TableRow>
        </TableHead>
        <TableBody>
          {/*Free Delivery row*/}
          {Object.values(deliveryOptions).length <= 0 && (
            <TableRow>
              <TableCell colSpan={2} align="left" className={classes.tableCellDeliveryOption}>
                <FormattedMessage id="cart.shipping.deliveryCosts.free" defaultMessage="Free" />
              </TableCell>
              <TableCell align="right">
                <span className={classes.price}>{formatPrice(0)}</span>
              </TableCell>
              <TableCell align="right">1</TableCell>
              <TableCell align="right">
                <span className={classes.price}>{formatPrice(0)}</span>
              </TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          )}

          {/*Paid Delivery row */}
          {Object.values(deliveryOptions).map((chosenDeliveryOption) => (
            <TableRow key={chosenDeliveryOption.contentful_id}>
              <TableCell colSpan={2} align="left">
                {chosenDeliveryOption.title}
              </TableCell>
              <TableCell align="right">
                <span className={classes.price}>{formatPrice(chosenDeliveryOption.price)}</span>
              </TableCell>
              <TableCell align="right">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button
                      color="info"
                      size="sm"
                      round
                      disabled={chosenDeliveryOption.quantity <= 1}
                      onClick={(): void =>
                        dispatch({
                          type: 'cart/removeDeliveryOptionFromCart',
                          payload: {
                            newDeliveryOption: chosenDeliveryOption,
                            quantity: 1,
                          },
                        })
                      }
                    >
                      <Remove />
                    </Button>
                    <Button
                      color="info"
                      size="sm"
                      round
                      onClick={(): void =>
                        dispatch({
                          type: 'cart/addDeliveryOptionToCart',
                          payload: {
                            newDeliveryOption: chosenDeliveryOption,
                            quantity: 1,
                          },
                        })
                      }
                    >
                      <Add />
                    </Button>
                  </ButtonGroup>
                  <div style={{ marginLeft: '5px' }}>{chosenDeliveryOption.quantity}</div>
                </div>
              </TableCell>
              <TableCell align="right">
                <span className={classes.price}>{formatPrice(calculateAmount(chosenDeliveryOption))}</span>
              </TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
