import React, { useContext, useState, Fragment } from 'react';
import { CartDispatchContext, CartStateContext } from '../store/StateProvider';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import { Button } from '../components/Button/Button';
import { DefaultHeader } from '../components/Header/Header';
import { cartStyle } from '../assets/jss/pages/cartStyle';
import { Parallax } from '../components/Parallax/Parallax';
import classNames from 'classnames';
import { calculateTotalNumber } from '../helpers/productsHelper';
import { DeliverOptionsNode } from '../interfaces/contentful';
import { EmptyCartBlock } from '../components/ShoppingCart/EmptyCartBlock';
import { ProductsInCartTableBlock } from '../components/ShoppingCart/ProductsInCartTableBlock';
import { DeliveryCostsTableBlock } from '../components/ShoppingCart/DeliveryCostsTableBlock';
import { OrderSummaryTableBlock } from '../components/ShoppingCart/OrderSummaryTableBlock';
import { ShoppingCartDeliveryOptions, ShoppingCartProducts } from '../store/reducers/CartReducer';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';

interface DeliveryOptionsData {
  deliveryOptionsNodes: {
    edges: [
      {
        node: DeliverOptionsNode;
      },
    ];
  };
}

export interface UserDetails {
  delivery: 'local' | 'bennekom' | 'together' | null;
  wechat: string;
  email: string;
  address: string;
  description: string;
}

const useStyles = makeStyles(cartStyle);

const Cart: React.FC = () => {
  const { products, deliveryOptions } = useContext(CartStateContext);
  const dispatch = useContext(CartDispatchContext);
  const { deliveryOptionsNodes } = useStaticQuery<DeliveryOptionsData>(graphql`
    query AddressFormQuery {
      deliveryOptionsNodes: allContentfulDeliveryOptions {
        edges {
          node {
            price
            title
            contentful_id
          }
        }
      }
    }
  `);

  if (process.env.GATSBY_PREORDERING_STARTED !== 'true') {
    typeof window !== `undefined` && navigate('/');
    return null;
  }

  const intl = useIntl();
  const classes = useStyles();
  const [userDetails, setUserDetails] = React.useState<UserDetails>({
    delivery:
      typeof window !== `undefined` && window.localStorage.getItem('cb_delivery')
        ? (window.localStorage.getItem('cb_delivery') as UserDetails['delivery'])
        : null,
    wechat: '',
    email: '',
    address: '',
    description: '',
  });
  const [ordering, setOrdering] = useState(false);
  const [orderedError, setOrderedError] = useState('');
  const [orderedSuccessfully, setOrderedSuccessfully] = useState(false);

  const numberOfProducts = calculateTotalNumber(products);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const id = event.target.id;
    const value = (event.target as HTMLInputElement).value as UserDetails['delivery'];

    if (event.target.name === 'cb_delivery') {
      if (typeof window !== `undefined`) {
        window.localStorage.setItem('cb_delivery', value!);
      }

      if (value === 'local') {
        const firstDeliveryNode = deliveryOptionsNodes.edges[0].node;

        dispatch({
          type: 'cart/addDeliveryOptionToCart',
          payload: {
            newDeliveryOption: firstDeliveryNode,
            quantity: 1,
          },
        });
      } else {
        dispatch({
          type: 'cart/clearDeliveryOptionFromCart',
        });
      }
    }

    setUserDetails({
      ...userDetails,
      [id]: value,
    });
  };

  /**
   *
   * @param event
   * @param userDetails
   * @param products
   * @param deliveryOptions
   */
  const placeOrder = async (
    event: React.FormEvent<HTMLFormElement>,
    userDetails: UserDetails,
    products: ShoppingCartProducts,
    deliveryOptions: ShoppingCartDeliveryOptions,
  ): Promise<void> => {
    event.preventDefault();
    setOrdering(true);

    const preparedProducts = Object.values(products).map((product) => {
      return {
        id: product.contentful_id,
        slug: product.slug,
        name: product.title,
        price: product.discountedPrice || product.price,
        quantity: product.quantity,
      };
    });

    let deliveryCosts = null;
    if (userDetails.delivery === 'local') {
      // If there will be more, this is a bit naive, isn't it ? :)
      const mainDeliveryOption = Object.values(deliveryOptions)[0];
      deliveryCosts = {
        id: mainDeliveryOption.contentful_id,
        name: mainDeliveryOption.title,
        price: mainDeliveryOption.price,
        quantity: mainDeliveryOption.quantity,
      };
    }

    // Remove all keys with falsy values
    const preparedUserDetails = Object.entries(userDetails).reduce((acc, [key, val]) => {
      return val ? { ...acc, [key]: val } : acc;
    }, {});

    const data = {
      deliveryCosts,
      userDetails: preparedUserDetails,
      products: preparedProducts,
    };

    // console.log('Sending', data);

    const options: RequestInit = {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATSBY_ORDER_API_KEY!,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    };

    try {
      const response = await fetch(process.env.GATSBY_ORDER_API_URL!, options);
      const responseData = await response.json();
      // console.log(responseData);
      setOrderedError('');
      dispatch({ type: 'cart/clearCart' });
      typeof window !== `undefined` && window.localStorage.removeItem('cb_delivery');
      setOrderedSuccessfully(true);
      setOrdering(false);
      await navigate('/thankyou');
    } catch (err) {
      console.warn(err.message);
      setOrderedError(err.message);
    } finally {
      setOrdering(false);
    }
  };

  return (
    <div className={classes.cartPage}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'breadcrumbs.cart' })}</title>
      </Helmet>
      <DefaultHeader />

      <Parallax image={require('../assets/img/bg9.jpg')} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography color="secondary">
              <FormattedMessage id="breadcrumbs.cart" defaultMessage="Cart" />
            </Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            {!orderedSuccessfully && <EmptyCartBlock numberOfProducts={numberOfProducts} />}
            <ProductsInCartTableBlock numberOfProducts={numberOfProducts} products={products} />

            {numberOfProducts > 0 && (
              <Fragment>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                  <FormattedMessage id="cart.shipping" defaultMessage="Shipping" />
                </Typography>
                <form
                  className={classes.form}
                  autoComplete="off"
                  onSubmit={(form): Promise<void> => placeOrder(form, userDetails, products, deliveryOptions)}
                >
                  <FormControl component="fieldset" className={classes.formControl} disabled={ordering}>
                    <FormLabel component="legend">
                      <FormattedMessage id="cart.shipping.courier" defaultMessage="Courier" />*
                    </FormLabel>
                    <RadioGroup
                      aria-label="delivery"
                      name="cb_delivery"
                      value={userDetails.delivery}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="local"
                        control={<Radio id="delivery" required />}
                        label={<FormattedMessage id="cart.shipping.courier.local" defaultMessage="Local" />}
                      />
                      <FormControlLabel
                        value="bennekom"
                        control={<Radio id="delivery" required />}
                        label={<FormattedMessage id="cart.shipping.courier.bennekom" defaultMessage="Bennekom" />}
                      />
                      <FormControlLabel
                        value="together"
                        control={<Radio id="delivery" required />}
                        label={<FormattedMessage id="cart.shipping.courier.together" defaultMessage="Together" />}
                      />
                    </RadioGroup>
                  </FormControl>

                  {userDetails.delivery && (
                    <Fragment>
                      <DeliveryCostsTableBlock deliveryOptions={deliveryOptions} />
                      <Grid container>
                        <Grid item sm={6} md={6}>
                          <br />
                          <Typography variant="h5" component="h2" gutterBottom>
                            <FormattedMessage id="cart.deliveryDetails" defaultMessage="Delivery details" />
                          </Typography>
                          <TextField
                            fullWidth
                            type="email"
                            name="cb_email"
                            value={userDetails.email}
                            required
                            id="email"
                            label={<FormattedMessage id="cart.deliveryDetails.email" defaultMessage="Email" />}
                            variant="outlined"
                            className={classes.field}
                            helperText={
                              <FormattedMessage
                                id="cart.deliveryDetails.email.helperText"
                                defaultMessage="You will receive the order confirmation email on this email."
                              />
                            }
                            onChange={handleChange}
                            disabled={ordering}
                          />
                          <TextField
                            fullWidth
                            type="text"
                            name="cb_address"
                            value={userDetails.address}
                            required
                            id="address"
                            label={<FormattedMessage id="cart.deliveryDetails.address" defaultMessage="Address" />}
                            variant="outlined"
                            className={classes.field}
                            helperText={
                              <FormattedMessage
                                id="cart.deliveryDetails.address.helperText"
                                defaultMessage="Please provide street, street number, city, postal number and country"
                              />
                            }
                            onChange={handleChange}
                            disabled={ordering}
                          />
                          <TextField
                            fullWidth
                            value={userDetails.wechat}
                            name="cb_wechat"
                            id="wechat"
                            label={<FormattedMessage id="cart.deliveryDetails.wechat" defaultMessage="Wechat" />}
                            variant="outlined"
                            className={classes.field}
                            helperText={
                              <FormattedMessage
                                id="cart.deliveryDetails.wechat.helperText"
                                defaultMessage="Wechat number"
                              />
                            }
                            onChange={handleChange}
                            inputProps={{ minLength: 2 }}
                            disabled={ordering}
                          />
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            id="description"
                            name="cb_description"
                            label={
                              <FormattedMessage
                                id="cart.deliveryDetails.orderDescription"
                                defaultMessage="Order description"
                              />
                            }
                            variant="outlined"
                            className={classes.field}
                            onChange={handleChange}
                            disabled={ordering}
                          />
                        </Grid>
                        <Grid item sm={1} md={1}>
                          &nbsp;
                        </Grid>
                        <Grid item sm={5} md={5}>
                          <br />
                          <Typography variant="h5" component="h2" gutterBottom>
                            <FormattedMessage id="cart.orderSummary" defaultMessage="Order summary" />
                          </Typography>
                          <OrderSummaryTableBlock deliveryOptions={deliveryOptions} products={products} />
                        </Grid>
                      </Grid>
                      <div>
                        <Button type="submit" color="info" disabled={ordering}>
                          <FormattedMessage id="cart.placeOrder" defaultMessage="Place order" />
                        </Button>
                      </div>
                    </Fragment>
                  )}
                </form>
              </Fragment>
            )}

            {numberOfProducts > 0 && orderedError.length > 0 && (
              <h3>
                <FormattedMessage
                  id="cart.placeOrder.error"
                  defaultMessage="Problem with ordering. Contact us or try again later on."
                />
              </h3>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cart;
