import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cartStyle } from '../../assets/jss/pages/cartStyle';
import { formatPrice } from '../../helpers/formatterHelper';
import {
  calculateDeliveryAmount,
  calculateTotalAmount,
  calculateTotalAmountWithDelivery,
} from '../../helpers/pricingHelper';
import { ShoppingCartDeliveryOptions, ShoppingCartProducts } from '../../store/reducers/CartReducer';
import { FormattedMessage } from 'react-intl';

interface TotalPriceTableBlockProps {
  deliveryOptions: ShoppingCartDeliveryOptions;
  products: ShoppingCartProducts;
}

const useStyles = makeStyles(cartStyle);

export const OrderSummaryTableBlock: React.FC<TotalPriceTableBlockProps> = ({ deliveryOptions, products }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow style={{ textTransform: 'uppercase' }}>
            <TableCell align="right">
              <FormattedMessage id="cart.orderSummary.item" defaultMessage="Item" />
            </TableCell>
            <TableCell align="right" style={{ width: '150px' }}>
              <FormattedMessage id="cart.orderSummary.amount" defaultMessage="Amount" />
            </TableCell>
            <TableCell style={{ width: '80px' }}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">
              <FormattedMessage id="cart.orderSummary.allProducts" defaultMessage="All products" />
            </TableCell>
            <TableCell align="right">
              <span className={classes.price}>{formatPrice(calculateTotalAmount(products))}</span>
            </TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">
              <FormattedMessage id="cart.orderSummary.deliveryCosts" defaultMessage="Delivery costs" />
            </TableCell>
            <TableCell align="right">
              <span className={classes.price}>{formatPrice(calculateDeliveryAmount(deliveryOptions))}</span>
            </TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellTotal} style={{ textTransform: 'uppercase' }}>
              <FormattedMessage id="cart.orderSummary.toPay" defaultMessage="To pay" />
            </TableCell>
            <TableCell align="right" className={classes.tableCellTotal}>
              {formatPrice(calculateTotalAmountWithDelivery(products, deliveryOptions))}
            </TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
