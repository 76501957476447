import React, { Fragment, useContext } from 'react';
import {
  ButtonGroup,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { cartStyle } from '../../assets/jss/pages/cartStyle';
import Img from 'gatsby-image';
import { formatPrice } from '../../helpers/formatterHelper';
import { Button } from '../Button/Button';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import { calculateAmount, calculateTotalAmount } from '../../helpers/pricingHelper';
import { Close } from '@material-ui/icons';
import { ShoppingCartProducts } from '../../store/reducers/CartReducer';
import { CartDispatchContext } from '../../store/StateProvider';
import { FormattedMessage } from 'react-intl';

interface ProductsInCartBlockProps {
  numberOfProducts: number;
  products: ShoppingCartProducts;
}

const useStyles = makeStyles(cartStyle);

export const ProductsInCartTableBlock: React.FC<ProductsInCartBlockProps> = ({ numberOfProducts, products }) => {
  const classes = useStyles();
  const dispatch = useContext(CartDispatchContext);

  if (numberOfProducts <= 0) return null;

  return (
    <Fragment>
      <Typography variant="h5" component="h2" gutterBottom>
        <FormattedMessage id="cart.products" defaultMessage="Products" />
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ textTransform: 'uppercase' }}>
              <TableCell align="left" style={{ width: '120px' }}>
                <FormattedMessage id="cart.products.image" defaultMessage="Image" />
              </TableCell>
              <TableCell align="left" style={{ width: '278px' }}>
                <FormattedMessage id="cart.products.product" defaultMessage="Product" />
              </TableCell>
              <TableCell align="right" style={{ width: '150px' }}>
                <FormattedMessage id="cart.products.price" defaultMessage="Price" />
              </TableCell>
              <TableCell align="right" style={{ width: '220px' }}>
                <FormattedMessage id="cart.products.quantity" defaultMessage="QTY" />
              </TableCell>
              <TableCell align="right" style={{ width: '150px' }}>
                <FormattedMessage id="cart.products.amount" defaultMessage="Amount" />
              </TableCell>
              <TableCell style={{ width: '80px' }}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(products).map((product) => (
              <TableRow key={product.contentful_id}>
                <TableCell>
                  <div className={classes.imgContainer}>
                    <Img fluid={product.cover.fluid} />
                  </div>
                </TableCell>

                <TableCell component="th" scope="row">
                  <Link component={GatsbyLink} to={`/products/${product.slug}`} className={classes.link}>
                    {product.title}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <span className={classes.price}>{formatPrice(product.discountedPrice || product.price)}</span>
                </TableCell>
                <TableCell align="right">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button
                        color="info"
                        size="sm"
                        round
                        onClick={(): void =>
                          dispatch({
                            type: 'cart/removeFromCart',
                            payload: {
                              newProduct: product,
                              quantity: 1,
                            },
                          })
                        }
                      >
                        <Remove />
                      </Button>
                      <Button
                        color="info"
                        size="sm"
                        round
                        onClick={(): void =>
                          dispatch({
                            type: 'cart/addToCart',
                            payload: {
                              newProduct: product,
                              quantity: 1,
                            },
                          })
                        }
                      >
                        <Add />
                      </Button>
                    </ButtonGroup>
                    <div style={{ marginLeft: '5px' }}>{product.quantity}</div>
                  </div>
                </TableCell>
                <TableCell align="right">
                  <span className={classes.price}>{formatPrice(calculateAmount(product))}</span>
                </TableCell>
                <TableCell>
                  <Button
                    link
                    style={{ margin: 0, padding: '5px' }}
                    onClick={(): void =>
                      dispatch({
                        type: 'cart/removeFromCart',
                        payload: {
                          newProduct: product,
                          quantity: product.quantity,
                        },
                      })
                    }
                  >
                    <Close />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell className={classes.tableCellTotal} colSpan={4} style={{ textTransform: 'uppercase' }}>
                <FormattedMessage id="cart.products.totalPrice" defaultMessage="Total price" />
              </TableCell>
              <TableCell align="right" className={classes.tableCellTotal}>
                {formatPrice(calculateTotalAmount(products))}
              </TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
